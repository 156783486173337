var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5 background justify-content-center",attrs:{"lg":"8"}},[_c('h1',{staticClass:"text-uppercase text-center text-light display-3"},[_vm._v(" "+_vm._s(_vm.$t('loginTitle'))+" ")])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo","width":"150"}})],1),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('createAccount'))+" 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" YoConstruyo ")]),_c('validation-observer',{ref:"registerValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.register()}}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"WhatsApp"}},[_c('validation-provider',{attrs:{"name":"WhatsApp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"number"},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Referido ( Obligatorio )"}},[_c('validation-provider',{attrs:{"name":"Referido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text"},model:{value:(_vm.user.referred_by),callback:function ($$v) {_vm.$set(_vm.user, "referred_by", $$v)},expression:"user.referred_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.loading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('createAccount'))+" ")])],1)],1)],1),_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"text-primary cursor-pointer",attrs:{"block":""},on:{"click":function($event){return _vm.$router.push({ name: 'auth-login' })}}},[_vm._v(" "+_vm._s(_vm.$t('alreadyHaveAnAccount'))+" ")])])],1)],1)],1),_c('b-modal',{attrs:{"id":"register-complete","ok-only":"","title":_vm.$t('registerComplete')}},[_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t('registerCompleteInfo'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }